import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { StandingChargeFragment } from '@/services/typed-graphql-sdk';
import { StandingChargeUnitType } from '@/utils/constants/industry/industry';
import { padWithTrailingZeroes } from '@/utils/formatters/padNumberWithTrailingZeroesToTwoDecimals';

export const StandingChargesTimeOfUseMyPage: FC<{
	standingChargeUnitType: StandingChargeUnitType;
	standingCharges: StandingChargeFragment[];
}> = ({ standingCharges, standingChargeUnitType }) => {
	const { t } = useTranslation();

	const [firstStandingCharge] = standingCharges;

	/*
	 * When the standing charge unit type is YEN_DAY, the price is a flat rate that does not need to be multiplied.
	 * */
	const price = padWithTrailingZeroes(
		standingChargeUnitType === 'YEN_DAY'
			? Number(firstStandingCharge.pricePerUnitIncTax)
			: Number(firstStandingCharge.pricePerUnitIncTax) * 10,
		2
	);
	return (
		<div className="flex flex-col gap-3 text-base">
			<p className="font-bold">{t('obj:product.basic-charge')}:</p>{' '}
			<p className="font-medium">
				{t('price-with-yen', { price })}
				{standingChargeUnitType === 'YEN_DAY'
					? `/${t('day')}`
					: standingChargeUnitType === 'YEN_AMPERE_DAY'
						? `/10A/${t('day')}`
						: `/kVA/${t('day')}`}
			</p>
		</div>
	);
};
