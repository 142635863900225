import clsx from 'clsx';
import { Trans } from 'next-i18next';
import { FC } from 'react';

import { SteppedConsumptionRate } from '@/services/typed-graphql-sdk';
import { GridOperatorCode } from '@/utils/constants/industry/gridOperator';

type ConsumptionChargeCopy = {
	description: string;
	title: string;
};

const ConsumptionChargeCopyByGridOperatorCode: Record<
	GridOperatorCode,
	ConsumptionChargeCopy[]
> = {
	// Hokkaido
	'01': [
		{ title: 'デイタイム', description: '8:00~22:00' },
		{ title: 'ナイトタイム', description: '0:00~8:00, 22:00~24:00' },
	],
	// Tohoku
	'02': [
		{ title: 'デイタイム', description: '8:00~22:00' },
		{ title: 'ナイトタイム', description: '0:00~8:00, 22:00~24:00' },
	],
	// Tokyo
	'03': [
		{ title: 'デイタイム', description: '0:00~1:00, 6:00~24:00' },
		{ title: 'ナイトタイム', description: '1:00~6:00' },
	],
	// Chubu
	'04': [
		{ title: 'デイタイム', description: '10:00~17:00' },
		{ title: 'ホームタイム', description: '8:00~10:00, 17:00~22:00' },
		{ title: 'ナイトタイム', description: '0:00~8:00, 22:00~24:00' },
	],
	// Hokuriku
	'05': [
		{ title: 'デイタイム', description: '8:00~20:00' },
		{ title: 'ホームタイム', description: '0:00~8:00, 20:00~24:00' },
	],
	// Kansai
	'06': [
		{
			title: 'デイタイム（夏季）',
			description: '10:00~17:00 7月1日~9月30日',
		},
		{
			title: 'デイタイム（その他季）',
			description: '10:00~17:00 7月1日~9月30日以外',
		},
		{ title: 'ホームタイム', description: '7:00~10:00, 17:00~23:00' },
		{ title: 'ナイトタイム', description: '0:00~7:00, 23:00~24:00' },
	],
	// Chugoku
	'07': [
		{
			title: 'デイタイム（夏季）',
			description: '9:00~21:00 7月1日~9月30日',
		},
		{
			title: 'デイタイム（その他季）',
			description: '9:00~21:00 7月1日~9月30日以外',
		},
		{ title: 'ナイトタイム', description: '0:00~9:00, 21:00~24:00' },
	],
	// Shikoku
	'08': [
		{ title: 'デイタイム', description: '9:00~23:00' },
		{ title: 'ナイトタイム', description: '0:00~9:00, 23:00~24:00' },
	],
	// Kyushu
	'09': [
		{
			title: 'デイタイム (夏季、冬季)',
			description: '8:00~22:00, 7月1日~9月30日, 12月1日~2月28日（または29日）',
		},
		{
			title: 'デイタイム (その他季)',
			description:
				'8:00~22:00, 7月1日~9月30日, 12月1日~2月28日（または29日）以外',
		},
		{ title: 'ナイトタイム', description: '0:00~8:00, 22:00~24:00' },
	],
	'10': [],
};

/**
 * Display time of use consumption charges for All Electric tariff
 * Product time of use consumption charge details differer by grid operator area
 */
export const ConsumptionChargesAllElectric: FC<{
	consumptionCharges: SteppedConsumptionRate[];
	gridOperatorCode: GridOperatorCode;
	variant?: 'onboarding' | 'mypage';
}> = ({ consumptionCharges, gridOperatorCode, variant = 'mypage' }) => {
	const sortedCharges = consumptionCharges.sort(
		(a, b) => Number(b.pricePerUnitIncTax) - Number(a.pricePerUnitIncTax)
	);

	const consumptionChargeCopys =
		ConsumptionChargeCopyByGridOperatorCode[gridOperatorCode];

	return (
		<div className="flex w-full flex-col gap-3">
			{sortedCharges.map((charge, i) => {
				return (
					<div
						className="flex justify-between xs:text-base"
						key={charge.pricePerUnitIncTax}
					>
						<div className="flex flex-col text-ice">
							<span className="font-bold">
								{consumptionChargeCopys[i].title}
							</span>
							<span className="text-xs">
								{consumptionChargeCopys[i].description}
							</span>
						</div>
						<div
							className={clsx({
								'pt-3 text-xs': variant === 'onboarding',
							})}
						>
							<Trans
								i18nKey="obj:product.price-perkwh"
								values={{
									price: charge.pricePerUnitIncTax,
								}}
								components={{ span: <span /> }}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};
