import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
	ConsumptionChargesEVTimeOfUse,
	ConsumptionChargesSolarOctopusTimeOfUse,
} from '@/components/shared/ConsumptionCharges';
import { ConsumptionChargesAllElectric } from '@/components/shared/ConsumptionChargesAllElectric';
import { StandingChargesAllElectric } from '@/components/shared/StandingCharges/StandingChargesAllElectric';
import { StandingChargesDefault } from '@/components/shared/StandingCharges/StandingChargesDefault';
import { StandingChargesSimpleOctopus } from '@/components/shared/StandingCharges/StandingChargesSimpleOctopus';
import { StandingChargesTimeOfUseMyPage } from '@/components/shared/StandingCharges/StandingChargesTimeOfUseMyPage';
import {
	StandingChargeFragment,
	SteppedConsumptionRate,
} from '@/services/typed-graphql-sdk';
import { GridOperatorCode } from '@/utils/constants/industry/gridOperator';
import { StandingChargeUnitType } from '@/utils/constants/industry/industry';

export const ProductPriceMyPage: FC<{
	amperage?: Maybe<number>;
	consumptionCharges: SteppedConsumptionRate[];
	gridOperatorCode?: GridOperatorCode;
	isAllElectricProduct?: boolean;
	isElectricVehicleProduct?: boolean;
	isPriceTentative?: boolean;
	isSimpleOctopusProduct?: boolean;
	isSolarOctopusProduct?: boolean;
	kva?: Maybe<number>;
	standingChargePerDay?: string | null;
	standingChargeUnitType: StandingChargeUnitType;
	standingCharges: StandingChargeFragment[];
}> = ({
	amperage,
	consumptionCharges,
	gridOperatorCode,
	isAllElectricProduct,
	isElectricVehicleProduct,
	isSimpleOctopusProduct,
	isSolarOctopusProduct,
	kva,
	standingChargePerDay,
	standingCharges,
	standingChargeUnitType,
}) => {
	const { t } = useTranslation();
	return (
		<div className="space-y-4 text-ice">
			{/* Standing Charges Section */}
			{isAllElectricProduct ? (
				<StandingChargesAllElectric
					standingChargePerDay={standingChargePerDay}
					standingCharges={standingCharges}
				/>
			) : isElectricVehicleProduct || isSolarOctopusProduct ? (
				<StandingChargesTimeOfUseMyPage
					standingCharges={standingCharges}
					standingChargeUnitType={standingChargeUnitType}
				/>
			) : isSimpleOctopusProduct ? (
				<StandingChargesSimpleOctopus />
			) : (
				<StandingChargesDefault
					standingChargePerDay={standingChargePerDay}
					standingChargeUnitType={standingChargeUnitType}
					kva={kva}
					amperage={amperage}
					gridOperatorCode={gridOperatorCode}
				/>
			)}
			{/* Consumption Charges Section */}
			<div className={isSimpleOctopusProduct ? 'flex' : 'flex flex-col'}>
				<p className="mb-3 mr-1 text-base font-bold">
					{t('account:property-section.consumption-charge')}
					{isSimpleOctopusProduct ? '・' : ':'}
				</p>
				{isAllElectricProduct && gridOperatorCode ? (
					<ConsumptionChargesAllElectric
						consumptionCharges={consumptionCharges}
						gridOperatorCode={gridOperatorCode}
					/>
				) : isElectricVehicleProduct ? (
					<ConsumptionChargesEVTimeOfUse
						consumptionCharges={consumptionCharges}
					/>
				) : isSolarOctopusProduct ? (
					<ConsumptionChargesSolarOctopusTimeOfUse
						consumptionCharges={consumptionCharges}
					/>
				) : (
					<ConsumptionChargesMyPage consumptionCharges={consumptionCharges} />
				)}
			</div>
		</div>
	);
};

const ConsumptionChargesMyPage: FC<{
	consumptionCharges: SteppedConsumptionRate[];
}> = ({ consumptionCharges }) => (
	<div className="flex max-w-sm flex-wrap justify-between">
		{consumptionCharges.map((charge, index) => (
			<div
				className="flex flex-col"
				key={(charge.pricePerUnitIncTax + index).toString()}
			>
				<div className="whitespace-nowrap text-base font-bold">
					{charge.pricePerUnitIncTax}円/kWh
				</div>
				{charge.stepStart || charge.stepEnd ? (
					<div className="whitespace-nowrap text-xs text-pollution">
						{consumptionChargeStepLabel(charge)}
					</div>
				) : null}
			</div>
		))}
	</div>
);

const consumptionChargeStepLabel = (
	charge: Pick<SteppedConsumptionRate, 'stepEnd' | 'stepStart'>
): string => `${
	charge.stepStart && charge.stepStart > 0
		? charge.stepStart + 1
		: charge.stepStart
}
${charge.stepEnd ? ` ~ ${charge.stepEnd}kWh` : 'kWh ~'}`;
